/* eslint-disable array-callback-return */
/* eslint-disable max-len */
/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/no-array-index-key */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import FlexBoxItem from 'components/atoms/FlexBoxItem';
import IconLabelButton from 'components/molecules/IconLabelButton';
import FlexBox from 'components/atoms/FlexBox';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { useDownload } from './hooks';
import { isMacOs } from 'react-device-detect';
import useSortTable, { FieldSortState } from 'hooks/useSortTable';
import { MonthlyDailySalesManagementResult } from 'types/api/sales';
import { formatNumber } from 'utility/formatUtil';
import MonthlyDailySalesManagementReportDomain from 'domain/master/storeManagement/MonthlyDailySalesManagementReport';
import { grayScale } from 'components/styles';
import SortIcon from 'components/molecules/SortIcon';
import Icon from 'components/atoms/Icon';

const DEFAULT_SORT_FIELD = { fieldPath: 'targetDate', sort: 'asc' } as FieldSortState

const MonthlyDaySeparateSalesManagement: React.FC<{
  setIsLoading: any,
  orgCode: any,
  salesMangementReport: MonthlyDailySalesManagementResult,
  targetPeriodData: any,
  getMediaMst: any,
  compareToPreviousYear: any,
  orgName: any,
  roleScreen: any,
}> = ({
  setIsLoading,
  orgCode,
  salesMangementReport,
  targetPeriodData,
  getMediaMst,
  compareToPreviousYear,
  orgName,
  roleScreen,
}) => {

    const { detailList, total } = salesMangementReport;

    // ------------------------------------------------------------------
    // ソート
    // ------------------------------------------------------------------
    /** ソート実装 */
    const [{ sortField, sortedDataSource: dataSort }, { sortByFieldPath }] = useSortTable(detailList, DEFAULT_SORT_FIELD)

    // ------------------------------------------------------------------
    // 出力
    // ------------------------------------------------------------------
    const { downloadMonthlyReportCsv, downloadMonthlyReportExcel } = useDownload();

    /** 出力用データ */
    const outputDataList = useMemo(() => dataSort.map(d => d.getOutputData()), [dataSort]);

    /** 出力用合計データ */
    const totalInformation = [['合計', ...total.getOutputData().splice(1)]];

    let mergedRegions: any[] = [];
    const codeColumnIndex: any[] = [];

    const targetStoresData = `対象店舗：${orgName}`;

    let headerInformation: any = [];
    let subHeader: any = [];
    subHeader.push('', '売上', '', '');
    subHeader = getMediaMst ? subHeader.concat(Array(getMediaMst.length + 5).fill('')) : [];
    subHeader.push('値引・割引券', '', 'VOID', '', '会計中止', '', 'メニュー取消', '', 'レジ金', '', '', '');
    headerInformation.push(subHeader);
    if (getMediaMst && getMediaMst.length > 0) {
      subHeader = [];
      subHeader.push('', '純売上', '消費税', '総売上');
      subHeader.push('支払種別');
      subHeader = subHeader.concat(Array(getMediaMst.length - 1).fill(''));
      subHeader.push('金券販売', '客数', '客単価', '組数', '組単価');
      subHeader.push('', '', '', '');
      subHeader = subHeader.concat(Array(12).fill(''));
      headerInformation.push(subHeader);

      subHeader = ['日付', '', '', ''];
      if (getMediaMst && getMediaMst.length > 0) {
        getMediaMst.map((value: any) => {
          subHeader.push(value);
        });
      }
      subHeader.push('', '', '', '', '');
      subHeader.push('金額', '枚数', '金額', '件数', '金額', '件数', '金額', '件数', '釣銭準備金', '現金在高', '現金過不足', '銀行入金', 'データ手入力');
      headerInformation.push(subHeader);
    } else {
      subHeader = ['日付', '純売上', '消費税', '総売上'];
      subHeader.push('金券販売', '客数', '客単価', '組数', '組単価');
      subHeader.push('金額', '枚数', '金額', '件数', '金額', '件数', '金額', '件数', '釣銭準備金', '現金在高', '現金過不足', '銀行入金', 'データ手入力');
      headerInformation.push(subHeader);
    }

    const data = {
      footer: '',
      formName: '月次日別売上管理表',
      targetPeriod: targetPeriodData,
      targetStores: targetStoresData,
      compareToPreviousYear,
      headerInformation,
      searchResult: outputDataList,
      totalInformation,
    };

    headerInformation = [];
    subHeader = [];
    subHeader.push('日付', '売上', '', '');
    subHeader = getMediaMst ? subHeader.concat(Array(getMediaMst.length + 5).fill('')) : [];
    subHeader.push('値引・割引券', '', 'VOID', '', '会計中止', '', 'メニュー取消', '', 'レジ金', '', '', '', 'データ手入力');
    headerInformation.push(subHeader);
    subHeader = [];
    subHeader.push('', '純売上', '消費税', '総売上');
    if (getMediaMst && getMediaMst.length > 0) {
      subHeader.push('支払種別');
      subHeader = subHeader.concat(Array(getMediaMst.length - 1).fill(''));
    }
    subHeader.push('金券販売', '客数', '客単価', '組数', '組単価');

    subHeader = subHeader.concat(Array(12).fill(''));
    headerInformation.push(subHeader);

    subHeader = [];
    subHeader = subHeader.concat(Array(4).fill(''));
    if (getMediaMst && getMediaMst.length) {
      getMediaMst.map((value: any) => {
        subHeader.push(value);
      });
    }
    subHeader = subHeader.concat(Array(5).fill(''));
    subHeader.push('金額', '枚数', '金額', '件数', '金額', '件数', '金額', '件数', '釣銭準備金', '現金在高', '現金過不足', '銀行入金', '');
    headerInformation.push(subHeader);

    const dataExcel = {
      formName: '月次日別売上管理表',
      targetPeriod: targetPeriodData,
      targetStores: targetStoresData,
      compareToPreviousYear,
      headerInformation,
      searchResult: outputDataList,
      totalInformation,
      mergedRegions,
      codeColumnIndex,
    };

    const handleExportCSV = () => downloadMonthlyReportCsv(data, setIsLoading);
    const handleExportExcel = () => {
      if (mergedRegions) {
        mergedRegions = [];
      }
      mergedRegions.push([4, 6, 0, 0]);
      mergedRegions.push([5, 6, 1, 1]);
      mergedRegions.push([5, 6, 2, 2]);
      mergedRegions.push([5, 6, 3, 3]);
      mergedRegions.push([4, 4, 1, getMediaMst ? getMediaMst.length + 8 : 8]);
      if (getMediaMst && getMediaMst.length > 0) {
        if (getMediaMst.length > 1) {
          mergedRegions.push([5, 5, 4, 4 + getMediaMst.length - 1]);
        }
        mergedRegions.push([5, 6, 4 + getMediaMst.length, 4 + getMediaMst.length]);
        mergedRegions.push([5, 6, 4 + getMediaMst.length + 1, 4 + getMediaMst.length + 1]);
        mergedRegions.push([5, 6, 4 + getMediaMst.length + 2, 4 + getMediaMst.length + 2]);
        mergedRegions.push([5, 6, 4 + getMediaMst.length + 3, 4 + getMediaMst.length + 3]);
        mergedRegions.push([5, 6, 4 + getMediaMst.length + 4, 4 + getMediaMst.length + 4]);
        mergedRegions.push([4, 5, 9 + getMediaMst.length, 10 + getMediaMst.length]);
        mergedRegions.push([4, 5, 11 + getMediaMst.length, 12 + getMediaMst.length]);
        mergedRegions.push([4, 5, 13 + getMediaMst.length, 14 + getMediaMst.length]);
        mergedRegions.push([4, 5, 15 + getMediaMst.length, 16 + getMediaMst.length]);
        mergedRegions.push([4, 5, 17 + getMediaMst.length, 20 + getMediaMst.length]);
        mergedRegions.push([4, 6, 21 + getMediaMst.length, 21 + getMediaMst.length]);
      } else {
        mergedRegions.push([5, 6, 4, 4]);
        mergedRegions.push([5, 6, 5, 5]);
        mergedRegions.push([5, 6, 6, 6]);
        mergedRegions.push([5, 6, 7, 7]);
        mergedRegions.push([5, 6, 8, 8]);
        mergedRegions.push([4, 5, 9, 10]);
        mergedRegions.push([4, 5, 11, 12]);
        mergedRegions.push([4, 5, 13, 14]);
        mergedRegions.push([4, 5, 15, 16]);
        mergedRegions.push([4, 5, 17, 20]);
        mergedRegions.push([4, 6, 21, 21]);
      }
      dataExcel.mergedRegions = mergedRegions;
      dataExcel.codeColumnIndex = [];
      dataExcel.headerInformation = headerInformation;
      return downloadMonthlyReportExcel(dataExcel, setIsLoading);
    };

    const renderReportColumn = (item: MonthlyDailySalesManagementReportDomain) => (
      <>
        <td className="text-right"><span>{item.netSales}</span></td>
        <td className="text-right"><span>{item.tax}</span></td>
        <td className="text-right"><span>{item.totalSales}</span></td>
        {
          item.mediaList ? item.mediaList.map((mediaData: any, idx: any) => (
            <td className="text-right" key={idx}><span>{formatNumber(mediaData.paymentMediaAmount)}</span></td>
          )) : null
        }
        <td className="text-right"><span>{item.cashVoucherSalesTotalAmount}</span></td>
        <td className="text-right"><span>{item.guestCnt}</span></td>
        <td className="text-right"><span>{item.perCustomerPrice}</span></td>
        <td className="text-right"><span>{item.groupCnt}</span></td>
        <td className="text-right"><span>{item.perGroupPrice}</span></td>
        <td className="text-right"><span>{item.discountAmount}</span></td>
        <td className="text-right"><span>{item.discountCnt}</span></td>
        <td className="text-right"><span>{item.voidAmount}</span></td>
        <td className="text-right"><span>{item.voidCnt}</span></td>
        <td className="text-right"><span>{item.stopAccountAmount}</span></td>
        <td className="text-right"><span>{item.stopAccountCnt}</span></td>
        <td className="text-right"><span>{item.cancelMenuAmount}</span></td>
        <td className="text-right"><span>{item.cancelMenuCnt}</span></td>
        <td className="text-right"><span>{item.changeReserve}</span></td>
        <td className="text-right"><span>{item.cashStock}</span></td>
        <td className="text-right"><span>{item.cashExcessOrDeficiency}</span></td>
        <td className="text-right"><span>{item.bankDeposit}</span></td>
        <td className="text-right"><span style={{ width: '120px' }}>{item.editData}</span></td>
      </>
    );

    return (
      <div>
        <FlexBox>
          {
            roleScreen && roleScreen.downloadFlag === 1 && (
              <React.Fragment>
                <FlexBoxItem>
                  <IconLabelButton
                    onClick={handleExportCSV}
                    iconType="download"
                    text=" CSV出力"
                  />
                </FlexBoxItem>
                <FlexBoxItem>
                  <IconLabelButton
                    onClick={handleExportExcel}
                    iconType="download"
                    text=" EXCEL出力"
                  />
                </FlexBoxItem>
              </React.Fragment>
            )}
        </FlexBox>
        <div style={{ margin: '15px' }}>
          <div className="templateTable newDesignTable" style={{ height: 'auto', minHeight: '10%', maxHeight: 'calc(100vh - 300px)', paddingBottom: isMacOs ? '35px' : '1px' }} >
            <table className="table table-bordered text-nowrap">
              <thead>
                {/* frist row */}
                <tr>
                  {/* left */}
                  <th rowSpan={getMediaMst && getMediaMst.length > 0 ? 3 : 2} style={{ top: '0', left: '0', zIndex: 99, maxWidth: ' 200px', width: '200px', minWidth: '200px' }} className="text-center stickyStyle fristColSticky" onClick={e => sortByFieldPath('targetDate')}>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <span style={{ margin: 'auto' }}>日付</span>
                      {sortField.fieldPath === 'targetDate' && <SortIcon isSortedDesc={sortField.sort === 'desc'} />}
                      {sortField.fieldPath !== 'targetDate' && <Icon type="sortDefault" color={grayScale.gray100} size="25px" />}
                    </div>
                  </th>
                  <th colSpan={getMediaMst ? 8 + getMediaMst.length : 8} style={{ top: '0' }} className="text-center stickyStyle">売上</th>
                  {/* right */}
                  <th colSpan={2} rowSpan={getMediaMst && getMediaMst.length > 0 ? 2 : 1} style={{ top: '0' }} className="text-center stickyStyle"><span style={{ width: '100%' }}>値引・割引券</span></th>
                  <th colSpan={2} rowSpan={getMediaMst && getMediaMst.length > 0 ? 2 : 1} style={{ top: '0' }} className="text-center stickyStyle"><span style={{ width: '100%' }}>VOID</span></th>
                  <th colSpan={2} rowSpan={getMediaMst && getMediaMst.length > 0 ? 2 : 1} style={{ top: '0' }} className="text-center stickyStyle"><span style={{ width: '100%' }}>会計中止</span></th>
                  <th colSpan={2} rowSpan={getMediaMst && getMediaMst.length > 0 ? 2 : 1} style={{ top: '0' }} className="text-center stickyStyle"><span style={{ width: '100%' }}>メニュー取消</span></th>
                  <th colSpan={4} rowSpan={getMediaMst && getMediaMst.length > 0 ? 2 : 1} style={{ top: '0' }} className="text-center stickyStyle"><span style={{ width: '100%' }}>レジ金</span></th>
                  <th rowSpan={getMediaMst && getMediaMst.length > 0 ? 3 : 2} style={{ top: '0' }} className="text-center stickyStyle" onClick={e => sortByFieldPath('editData')}>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <span style={{ margin: 'auto' }}>データ手入力</span>
                      {sortField.fieldPath === 'editData' && <SortIcon isSortedDesc={sortField.sort === 'desc'} />}
                      {sortField.fieldPath !== 'editData' && <Icon type="sortDefault" color={grayScale.gray100} size="25px" />}
                    </div>
                  </th>
                </tr>
                {/* flexible second row  */}
                {(getMediaMst && getMediaMst.length > 0) &&
                  <tr>
                    <th rowSpan={2} style={{ top: '34px' }} className="text-center stickyStyle" onClick={e => sortByFieldPath('netSales', 'number')}>
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <span style={{ margin: 'auto' }}>純売上</span>
                        {sortField.fieldPath === 'netSales' && <SortIcon isSortedDesc={sortField.sort === 'desc'} />}
                        {sortField.fieldPath !== 'netSales' && <Icon type="sortDefault" color={grayScale.gray100} size="25px" />}
                      </div>
                    </th>
                    <th rowSpan={2} style={{ top: '34px' }} className="text-center stickyStyle" onClick={e => sortByFieldPath('tax', 'number')}>
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <span style={{ margin: 'auto' }}>消費税</span>
                        {sortField.fieldPath === 'tax' && <SortIcon isSortedDesc={sortField.sort === 'desc'} />}
                        {sortField.fieldPath !== 'tax' && <Icon type="sortDefault" color={grayScale.gray100} size="25px" />}
                      </div>
                    </th>
                    <th rowSpan={2} style={{ top: '34px' }} className="text-center stickyStyle" onClick={e => sortByFieldPath('totalSales', 'number')}>
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <span style={{ margin: 'auto' }}>総売上</span>
                        {sortField.fieldPath === 'totalSales' && <SortIcon isSortedDesc={sortField.sort === 'desc'} />}
                        {sortField.fieldPath !== 'totalSales' && <Icon type="sortDefault" color={grayScale.gray100} size="25px" />}
                      </div>
                    </th>
                    <th colSpan={getMediaMst.length} style={{ top: '34px' }} className="text-center stickyStyle">
                      <span style={{ margin: 'auto' }}>支払種別</span>
                    </th>
                    <th rowSpan={2} style={{ top: '34px' }} className="text-center stickyStyle" onClick={e => sortByFieldPath('cashVoucherSalesTotalAmount', 'number')}>
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <span style={{ margin: 'auto' }}>金券販売</span>
                        {sortField.fieldPath === 'cashVoucherSalesTotalAmount' && <SortIcon isSortedDesc={sortField.sort === 'desc'} />}
                        {sortField.fieldPath !== 'cashVoucherSalesTotalAmount' && <Icon type="sortDefault" color={grayScale.gray100} size="25px" />}
                      </div>
                    </th>
                    <th rowSpan={2} style={{ top: '34px' }} className="text-center stickyStyle" onClick={e => sortByFieldPath('guestCnt', 'number')}>
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <span style={{ margin: 'auto' }}>客数</span>
                        {sortField.fieldPath === 'guestCnt' && <SortIcon isSortedDesc={sortField.sort === 'desc'} />}
                        {sortField.fieldPath !== 'guestCnt' && <Icon type="sortDefault" color={grayScale.gray100} size="25px" />}
                      </div>
                    </th>
                    <th rowSpan={2} style={{ top: '34px' }} className="text-center stickyStyle" onClick={e => sortByFieldPath('perCustomerPrice', 'number')}>
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <span style={{ margin: 'auto' }}>客単価</span>
                        {sortField.fieldPath === 'perCustomerPrice' && <SortIcon isSortedDesc={sortField.sort === 'desc'} />}
                        {sortField.fieldPath !== 'perCustomerPrice' && <Icon type="sortDefault" color={grayScale.gray100} size="25px" />}
                      </div>
                    </th>
                    <th rowSpan={2} style={{ top: '34px' }} className="text-center stickyStyle" onClick={e => sortByFieldPath('groupCnt', 'number')}>
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <span style={{ margin: 'auto' }}>組数</span>
                        {sortField.fieldPath === 'groupCnt' && <SortIcon isSortedDesc={sortField.sort === 'desc'} />}
                        {sortField.fieldPath !== 'groupCnt' && <Icon type="sortDefault" color={grayScale.gray100} size="25px" />}
                      </div>
                    </th>
                    <th rowSpan={2} style={{ top: '34px' }} className="text-center stickyStyle" onClick={e => sortByFieldPath('perGroupPrice', 'number')}>
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <span style={{ margin: 'auto' }}>組単価</span>
                        {sortField.fieldPath === 'perGroupPrice' && <SortIcon isSortedDesc={sortField.sort === 'desc'} />}
                        {sortField.fieldPath !== 'perGroupPrice' && <Icon type="sortDefault" color={grayScale.gray100} size="25px" />}
                      </div>
                    </th>
                  </tr>}
                {/* third row */}
                <tr>
                  {/* left */}
                  {/* <th style={{ top: getMediaMst && getMediaMst.length > 0 ? '68px' : '34px', left: '0', zIndex: 99, maxWidth: ' 200px', width: '200px', minWidth: '200px' }} className="text-center stickyStyle fristColSticky" onClick={e => sortByFieldPath('targetDate', 'date')}>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <span style={{ margin: 'auto' }}>日付</span>
                      {sortField.fieldPath === 'targetDate' && <SortIcon isSortedDesc={sortField.sort === 'desc'} />}
                      {sortField.fieldPath !== 'targetDate' && <Icon type="sortDefault" color={grayScale.gray100} size="25px" />}
                    </div>
                  </th> */}
                  {/* right */}
                  {
                    !(getMediaMst && getMediaMst.length > 0) &&
                    <>
                      <th rowSpan={2} style={{ top: '34px' }} className="text-center stickyStyle" onClick={e => sortByFieldPath('netSales', 'number')}>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                          <span style={{ margin: 'auto' }}>純売上</span>
                          {sortField.fieldPath === 'netSales' && <SortIcon isSortedDesc={sortField.sort === 'desc'} />}
                          {sortField.fieldPath !== 'netSales' && <Icon type="sortDefault" color={grayScale.gray100} size="25px" />}
                        </div>
                      </th>
                      <th rowSpan={2} style={{ top: '34px' }} className="text-center stickyStyle" onClick={e => sortByFieldPath('tax', 'number')}>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                          <span style={{ margin: 'auto' }}>消費税</span>
                          {sortField.fieldPath === 'tax' && <SortIcon isSortedDesc={sortField.sort === 'desc'} />}
                          {sortField.fieldPath !== 'tax' && <Icon type="sortDefault" color={grayScale.gray100} size="25px" />}
                        </div>
                      </th>
                      <th rowSpan={2} style={{ top: '34px' }} className="text-center stickyStyle" onClick={e => sortByFieldPath('totalSales', 'number')}>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                          <span style={{ margin: 'auto' }}>総売上</span>
                          {sortField.fieldPath === 'totalSales' && <SortIcon isSortedDesc={sortField.sort === 'desc'} />}
                          {sortField.fieldPath !== 'totalSales' && <Icon type="sortDefault" color={grayScale.gray100} size="25px" />}
                        </div>
                      </th>
                      <th rowSpan={2} style={{ top: '34px' }} className="text-center stickyStyle" onClick={e => sortByFieldPath('cashVoucherSalesTotalAmount', 'number')}>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                          <span style={{ margin: 'auto' }}>金券販売</span>
                          {sortField.fieldPath === 'cashVoucherSalesTotalAmount' && <SortIcon isSortedDesc={sortField.sort === 'desc'} />}
                          {sortField.fieldPath !== 'cashVoucherSalesTotalAmount' && <Icon type="sortDefault" color={grayScale.gray100} size="25px" />}
                        </div>
                      </th>
                      <th rowSpan={2} style={{ top: '34px' }} className="text-center stickyStyle" onClick={e => sortByFieldPath('guestCnt', 'number')}>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                          <span style={{ margin: 'auto' }}>客数</span>
                          {sortField.fieldPath === 'guestCnt' && <SortIcon isSortedDesc={sortField.sort === 'desc'} />}
                          {sortField.fieldPath !== 'guestCnt' && <Icon type="sortDefault" color={grayScale.gray100} size="25px" />}
                        </div>
                      </th>
                      <th rowSpan={2} style={{ top: '34px' }} className="text-center stickyStyle" onClick={e => sortByFieldPath('perCustomerPrice', 'number')}>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                          <span style={{ margin: 'auto' }}>客単価</span>
                          {sortField.fieldPath === 'perCustomerPrice' && <SortIcon isSortedDesc={sortField.sort === 'desc'} />}
                          {sortField.fieldPath !== 'perCustomerPrice' && <Icon type="sortDefault" color={grayScale.gray100} size="25px" />}
                        </div>
                      </th>
                      <th rowSpan={2} style={{ top: '34px' }} className="text-center stickyStyle" onClick={e => sortByFieldPath('groupCnt', 'number')}>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                          <span style={{ margin: 'auto' }}>組数</span>
                          {sortField.fieldPath === 'groupCnt' && <SortIcon isSortedDesc={sortField.sort === 'desc'} />}
                          {sortField.fieldPath !== 'groupCnt' && <Icon type="sortDefault" color={grayScale.gray100} size="25px" />}
                        </div>
                      </th>
                      <th rowSpan={2} style={{ top: '34px' }} className="text-center stickyStyle" onClick={e => sortByFieldPath('perGroupPrice', 'number')}>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                          <span style={{ margin: 'auto' }}>組単価</span>
                          {sortField.fieldPath === 'perGroupPrice' && <SortIcon isSortedDesc={sortField.sort === 'desc'} />}
                          {sortField.fieldPath !== 'perGroupPrice' && <Icon type="sortDefault" color={grayScale.gray100} size="25px" />}
                        </div>
                      </th>
                    </>
                  }

                  {
                    getMediaMst && getMediaMst.map((media: any, index: any) => (

                      <th key={index} className="text-center stickyStyle" style={{ fontSize: '12px', top: '68px' }} onClick={e => {sortByFieldPath(`mediaList[${index}].paymentMediaAmount`, 'number')}}>
                        {/* <span>{media}</span> */}
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                          <span style={{ margin: 'auto' }}>{media}</span>
                          {sortField.fieldPath === `mediaList[${index}].paymentMediaAmount` && <SortIcon isSortedDesc={sortField.sort === 'desc'} />}
                          {sortField.fieldPath !== `mediaList[${index}].paymentMediaAmount` && <Icon type="sortDefault" color={grayScale.gray100} size="25px" />}
                        </div>
                      </th>
                    ))
                  }
                  <th style={{ top: getMediaMst && getMediaMst.length > 0 ? '68px' : '34px' }} className="text-center stickyStyle" onClick={e => sortByFieldPath('discountAmount', 'number')}>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <span style={{ margin: 'auto' }}>金額</span>
                      {sortField.fieldPath === 'discountAmount' && <SortIcon isSortedDesc={sortField.sort === 'desc'} />}
                      {sortField.fieldPath !== 'discountAmount' && <Icon type="sortDefault" color={grayScale.gray100} size="25px" />}
                    </div>
                  </th>
                  <th style={{ top: getMediaMst && getMediaMst.length > 0 ? '68px' : '34px' }} className="text-center stickyStyle" onClick={e => sortByFieldPath('discountCnt', 'number')}>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <span style={{ margin: 'auto' }}>枚数</span>
                      {sortField.fieldPath === 'discountCnt' && <SortIcon isSortedDesc={sortField.sort === 'desc'} />}
                      {sortField.fieldPath !== 'discountCnt' && <Icon type="sortDefault" color={grayScale.gray100} size="25px" />}
                    </div>
                  </th>
                  <th style={{ top: getMediaMst && getMediaMst.length > 0 ? '68px' : '34px' }} className="text-center stickyStyle" onClick={e => sortByFieldPath('voidAmount', 'number')}>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <span style={{ margin: 'auto' }}>金額</span>
                      {sortField.fieldPath === 'voidAmount' && <SortIcon isSortedDesc={sortField.sort === 'desc'} />}
                      {sortField.fieldPath !== 'voidAmount' && <Icon type="sortDefault" color={grayScale.gray100} size="25px" />}
                    </div>
                  </th>
                  <th style={{ top: getMediaMst && getMediaMst.length > 0 ? '68px' : '34px' }} className="text-center stickyStyle" onClick={e => sortByFieldPath('voidCnt', 'number')} >
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <span style={{ margin: 'auto' }}>件数</span>
                      {sortField.fieldPath === 'voidCnt' && <SortIcon isSortedDesc={sortField.sort === 'desc'} />}
                      {sortField.fieldPath !== 'voidCnt' && <Icon type="sortDefault" color={grayScale.gray100} size="25px" />}
                    </div>
                  </th>
                  <th style={{ top: getMediaMst && getMediaMst.length > 0 ? '68px' : '34px' }} className="text-center stickyStyle" onClick={e => sortByFieldPath('stopAccountAmount', 'number')}>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <span style={{ margin: 'auto' }}>金額</span>
                      {sortField.fieldPath === 'stopAccountAmount' && <SortIcon isSortedDesc={sortField.sort === 'desc'} />}
                      {sortField.fieldPath !== 'stopAccountAmount' && <Icon type="sortDefault" color={grayScale.gray100} size="25px" />}
                    </div>
                  </th>
                  <th style={{ top: getMediaMst && getMediaMst.length > 0 ? '68px' : '34px' }} className="text-center stickyStyle" onClick={e => sortByFieldPath('stopAccountCnt', 'number')}>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <span style={{ margin: 'auto' }}>件数</span>
                      {sortField.fieldPath === 'stopAccountCnt' && <SortIcon isSortedDesc={sortField.sort === 'desc'} />}
                      {sortField.fieldPath !== 'stopAccountCnt' && <Icon type="sortDefault" color={grayScale.gray100} size="25px" />}
                    </div>
                  </th>
                  <th style={{ top: getMediaMst && getMediaMst.length > 0 ? '68px' : '34px' }} className="text-center stickyStyle" onClick={e => sortByFieldPath('cancelMenuAmount', 'number')}>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <span style={{ margin: 'auto' }}>金額</span>
                      {sortField.fieldPath === 'cancelMenuAmount' && <SortIcon isSortedDesc={sortField.sort === 'desc'} />}
                      {sortField.fieldPath !== 'cancelMenuAmount' && <Icon type="sortDefault" color={grayScale.gray100} size="25px" />}
                    </div>
                  </th>
                  <th style={{ top: getMediaMst && getMediaMst.length > 0 ? '68px' : '34px' }} className="text-center stickyStyle" onClick={e => sortByFieldPath('cancelMenuCnt', 'number')}>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <span style={{ margin: 'auto' }}>件数</span>
                      {sortField.fieldPath === 'cancelMenuCnt' && <SortIcon isSortedDesc={sortField.sort === 'desc'} />}
                      {sortField.fieldPath !== 'cancelMenuCnt' && <Icon type="sortDefault" color={grayScale.gray100} size="25px" />}
                    </div>
                  </th>
                  <th style={{ top: getMediaMst && getMediaMst.length > 0 ? '68px' : '34px' }} className="text-center stickyStyle" onClick={e => sortByFieldPath('changeReserve', 'number')}>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <span style={{ margin: 'auto' }}>釣銭準備金</span>
                      {sortField.fieldPath === 'changeReserve' && <SortIcon isSortedDesc={sortField.sort === 'desc'} />}
                      {sortField.fieldPath !== 'changeReserve' && <Icon type="sortDefault" color={grayScale.gray100} size="25px" />}
                    </div>
                  </th>
                  <th style={{ top: getMediaMst && getMediaMst.length > 0 ? '68px' : '34px' }} className="text-center stickyStyle" onClick={e => sortByFieldPath('cashStock', 'number')}>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <span style={{ margin: 'auto' }}>現金在高</span>
                      {sortField.fieldPath === 'cashStock' && <SortIcon isSortedDesc={sortField.sort === 'desc'} />}
                      {sortField.fieldPath !== 'cashStock' && <Icon type="sortDefault" color={grayScale.gray100} size="25px" />}
                    </div>
                  </th>
                  <th style={{ top: getMediaMst && getMediaMst.length > 0 ? '68px' : '34px' }} className="text-center stickyStyle" onClick={e => sortByFieldPath('cashExcessOrDeficiency', 'number')} >
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <span style={{ margin: 'auto' }}>現金過不足</span>
                      {sortField.fieldPath === 'cashExcessOrDeficiency' && <SortIcon isSortedDesc={sortField.sort === 'desc'} />}
                      {sortField.fieldPath !== 'cashExcessOrDeficiency' && <Icon type="sortDefault" color={grayScale.gray100} size="25px" />}
                    </div>
                  </th>
                  <th style={{ top: getMediaMst && getMediaMst.length > 0 ? '68px' : '34px' }} className="text-center stickyStyle" onClick={e => sortByFieldPath('bankDeposit', 'number')}>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <span style={{ margin: 'auto' }}>銀行入金</span>
                      {sortField.fieldPath === 'bankDeposit' && <SortIcon isSortedDesc={sortField.sort === 'desc'} />}
                      {sortField.fieldPath !== 'bankDeposit' && <Icon type="sortDefault" color={grayScale.gray100} size="25px" />}
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                { dataSort && dataSort.map((item: any, index: any) => (
                  <tr key={index}>
                    {/* left */}
                    <td className="text-center stickyStyle fristColSticky" style={{ left: '0', zIndex: 10 }}>
                      <span>
                        <Link to={{ pathname: `/salesDailyReport/${item.orgCode}/${moment(item.rawData.targetDate, 'YYYY-MM-DD').format('YYYY-MM-DD')}` }}>
                          {item.targetDate}
                        </Link>
                      </span>
                    </td>
                    {renderReportColumn(item)}
                  </tr>
                ))}
                {/* footer */}
                <tr style={{ fontWeight: 'bolder' }}>
                  <td className="text-center stickyStyle fristColSticky" style={{ top: '0', left: '0', zIndex: 99 }}>合計</td>
                  {renderReportColumn(total)}
                </tr>
              </tbody>
            </table>

          </div>
        </div>
      </div>
    );
  };

export default MonthlyDaySeparateSalesManagement;
