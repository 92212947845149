import _ from "lodash";
import { formatNumber, formatPercent } from "utility/formatUtil";

export interface ItemCategoryReport {
  /** ランク */
  itemRank: string,
  /** 順位 */
  itemOrder: number,
  /** 小部門コード */
  smallDivisionCode: string,
  /** 小部門名称 */
  smallDivisionName: string,
  /** 数量 */
  totalItem: number,
  /** 金額 */
  totalPrice: number,
  /** 構成比 */
  compositionRatio: number,
  /** 累計比 */
  cumulativeRatio: number,
  /** 合計原価 */
  totalCost: number,
  /** 利益 */
  profit: number,
}

export default class ItemCategoryReportDomain {
  _itemRank: string;
  _itemOrder: string;
  _smallDivisionCode: string;
  _smallDivisionName: string;
  _totalItem: string;
  _totalPrice: string;
  _compositionRatio: string;
  _cumulativeRatio: string;
  _totalCost: string;
  _profit: string;

  constructor(private rawData: ItemCategoryReport) {
    this._itemRank = rawData.itemRank;
    this._itemOrder = formatNumber(rawData.itemOrder);
    this._smallDivisionCode = rawData.smallDivisionCode;
    this._smallDivisionName = rawData.smallDivisionName;
    this._totalItem = formatNumber(rawData.totalItem);
    this._totalPrice = formatNumber(rawData.totalPrice);
    this._compositionRatio = formatPercent(rawData.compositionRatio);
    this._cumulativeRatio = formatPercent(rawData.cumulativeRatio);
    this._totalCost = formatNumber(rawData.totalCost);
    this._profit = formatNumber(rawData.profit);
  }

  static generateInitial(): ItemCategoryReportDomain {
    return new ItemCategoryReportDomain({
      itemRank: '',
      itemOrder: 0,
      smallDivisionCode: '',
      smallDivisionName: '',
      totalItem: 0,
      totalPrice: 0,
      compositionRatio: 0,
      cumulativeRatio: 0,
      totalCost: 0,
      profit: 0,
    });
  }

  getRawData(): ItemCategoryReport {
    return this.rawData;
  }

  /** 表示データ（画面非表示のプロパティを除外） */
  getDispData(): Partial<this>{
    return _.omit(this, ['rawData']);
  }

  /** 出力データ（CSV出力など） */
  getOutputData(): Array<Partial<this>> {
    return Object.values(this.getDispData());
  }

  get itemRank(): string {
    return this._itemRank;
  }

  get itemOrder(): string {
    return this._itemOrder;
  }

  get smallDivisionCode(): string {
    return this._smallDivisionCode;
  }

  get smallDivisionName(): string {
    return this._smallDivisionName;
  }

  get totalItem(): string {
    return this._totalItem;
  }

  get totalPrice(): string {
    return this._totalPrice;
  }

  get compositionRatio(): string {
    return this._compositionRatio;
  }

  get cumulativeRatio(): string {
    return this._cumulativeRatio;
  }

  get totalCost(): string {
    return this._totalCost;
  }

  get profit(): string {
    return this._profit;
  }

}
