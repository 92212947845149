import _ from "lodash";
import moment from 'moment';
import { formatNumber } from "utility/formatUtil";

export interface MonthlyDailyPaymentMedia {
    /** 支払種別コード */
    paymentMediaCode: string;
    /** 支払種別名 */
    paymentMediaName: string;
    /** 支払金額 */
    paymentMediaAmount: number;
    /** POS種別 */
    posType: number;
    /** 並び順 */
    dispOrder: number;
}

export interface MonthlyDailySalesManagementReport {
    /** 企業コード */
    companyCode: string;
    /** 店舗コード */
    orgCode: string;
    /** 日付 */
    targetDate: Date;
    /** 純売上 */
    netSales: number;
    /** 消費税 */
    tax: number,
    /** 総売上 */
    totalSales: number,
    /** 金券販売 */
    cashVoucherSalesTotalAmount:number,
    /** 客数 */
    guestCnt:number,
    /** 客単価 */
    perCustomerPrice: number,
    /** 組数 */
    groupCnt: number,
    /** 組単価 */
    perGroupPrice: number,
    /** 値引・割引券 金額 */
    discountAmount: number,
    /** 値引・割引券 枚数 */
    discountCnt: number,
    /** VOID 金額 */
    voidAmount: number,
    /** VOID 件数 */
    voidCnt: number,
    /** 会計中止 金額 */
    stopAccountAmount: number,
    /** 会計中止 件数 */
    stopAccountCnt: number,
    /** メニュー取消 金額 */
    cancelMenuAmount: number,
    /** メニュー取消 件数 */
    cancelMenuCnt: number,
    /** 釣銭準備金 */
    changeReserve: number,
    /** 現金在高 */
    cashStock: number,
    /** 現金過不足 */
    cashExcessOrDeficiency: number,
    /** 銀行入金 */
    bankDeposit: number,
    /** データ修正フラグ */
    editData: string,
    /** 支払種別リスト */
    mediaList: Array<MonthlyDailyPaymentMedia>,
  }

/** 月次日別帳票 売上管理表  */
export default class MonthlyDailySalesManagementReportDomain {

  private _orgCode: string;
  private _targetDate: string;
  private _netSales: string;
  private _tax: string;
  private _totalSales: string;
  private _cashVoucherSalesTotalAmount: string;
  private _guestCnt: string;
  private _perCustomerPrice: string;
  private _groupCnt: string;
  private _perGroupPrice: string;
  private _discountAmount: string;
  private _discountCnt: string;
  private _voidAmount: string;
  private _voidCnt: string;
  private _stopAccountAmount: string;
  private _stopAccountCnt: string;
  private _cancelMenuAmount: string;
  private _cancelMenuCnt: string;
  private _changeReserve: string;
  private _cashStock: string;
  private _cashExcessOrDeficiency: string;
  private _bankDeposit: string;
  private _editData: string;
  private _mediaList: Array<MonthlyDailyPaymentMedia>;


  constructor(private rawData: MonthlyDailySalesManagementReport) {
    this._orgCode = this.rawData.orgCode;
    this._targetDate = moment(this.rawData.targetDate, 'YYYY-MM-DD').format('YYYY年MM月DD日（ddd）');
    this._netSales = formatNumber(this.rawData.netSales);
    this._tax = formatNumber(this.rawData.tax);
    this._totalSales = formatNumber(this.rawData.totalSales);
    this._cashVoucherSalesTotalAmount = formatNumber(this.rawData.cashVoucherSalesTotalAmount);
    this._guestCnt = formatNumber(this.rawData.guestCnt);
    this._perCustomerPrice = formatNumber(this.rawData.perCustomerPrice);
    this._groupCnt = formatNumber(this.rawData.groupCnt);
    this._perGroupPrice = formatNumber(this.rawData.perGroupPrice);
    this._discountAmount = formatNumber(this.rawData.discountAmount);
    this._discountCnt = formatNumber(this.rawData.discountCnt);
    this._voidAmount = formatNumber(this.rawData.voidAmount);
    this._voidCnt = formatNumber(this.rawData.voidCnt);
    this._stopAccountAmount = formatNumber(this.rawData.stopAccountAmount);
    this._stopAccountCnt = formatNumber(this.rawData.stopAccountCnt);
    this._cancelMenuAmount = formatNumber(this.rawData.cancelMenuAmount);
    this._cancelMenuCnt = formatNumber(this.rawData.cancelMenuCnt);
    this._changeReserve = formatNumber(this.rawData.changeReserve);
    this._cashStock = formatNumber(this.rawData.cashStock);
    this._cashExcessOrDeficiency = formatNumber(this.rawData.cashExcessOrDeficiency);
    this._bankDeposit = formatNumber(this.rawData.bankDeposit);
    this._editData = this.rawData.editData;
    this._mediaList = this.rawData.mediaList;
  }

  static generateInitial(): MonthlyDailySalesManagementReportDomain {
    return new MonthlyDailySalesManagementReportDomain({
      companyCode: '',
      orgCode: '',
      targetDate: new Date(),
      netSales: 0,
      tax: 0,
      totalSales: 0,
      cashVoucherSalesTotalAmount: 0,
      guestCnt: 0,
      perCustomerPrice: 0,
      groupCnt: 0,
      perGroupPrice: 0,
      discountAmount: 0,
      discountCnt: 0,
      voidAmount: 0,
      voidCnt: 0,
      stopAccountAmount: 0,
      stopAccountCnt: 0,
      cancelMenuAmount: 0,
      cancelMenuCnt: 0,
      changeReserve: 0,
      cashStock: 0,
      cashExcessOrDeficiency: 0,
      bankDeposit: 0,
      editData: '',
      mediaList: [],
    });
  }

  getRawData(): MonthlyDailySalesManagementReport {
    return this.rawData;
  }

  /** 表示データ（画面非表示のプロパティを除外） */
  getDispData(): Partial<this>{
    return _.omit(this, ['rawData']);
  }

  /** 出力データ（CSV出力など） */
  getOutputData(): Array<string> {
    const tmpList1 = [ this.targetDate, this.netSales, this.tax, this.totalSales, ];
    const tmpMediaList  = this.mediaList.map(m => formatNumber(m.paymentMediaAmount));
    const tmpList2 = [
      this.cashVoucherSalesTotalAmount,
      this.guestCnt,
      this.perCustomerPrice,
      this.groupCnt,
      this.perGroupPrice,
      this.discountAmount,
      this.discountCnt,
      this.voidAmount,
      this.voidCnt,
      this.stopAccountAmount,
      this.stopAccountCnt,
      this.cancelMenuAmount,
      this.cancelMenuCnt,
      this.changeReserve,
      this.cashStock,
      this.cashExcessOrDeficiency,
      this.bankDeposit,
      this.editData,
    ];
    return [...tmpList1, ...tmpMediaList, ...tmpList2];
  }

  get orgCode(): string {
    return this._orgCode;
  }

  get targetDate(): string {
    return this._targetDate;
  }

  get netSales(): string {
    return this._netSales;
  }

  get tax(): string {
    return this._tax;
  }

  get totalSales(): string {
    return this._totalSales;
  }

  get cashVoucherSalesTotalAmount(): string {
    return this._cashVoucherSalesTotalAmount;
  }

  get guestCnt(): string {
    return this._guestCnt;
  }

  get perCustomerPrice(): string {
    return this._perCustomerPrice;
  }

  get groupCnt(): string {
    return this._groupCnt;
  }

  get perGroupPrice(): string {
    return this._perGroupPrice;
  }

  get discountAmount(): string {
    return this._discountAmount;
  }

  get discountCnt(): string {
    return this._discountCnt;
  }

  get voidAmount(): string {
    return this._voidAmount;
  }

  get voidCnt(): string {
    return this._voidCnt;
  }

  get stopAccountAmount(): string {
    return this._stopAccountAmount;
  }

  get stopAccountCnt(): string {
    return this._stopAccountCnt;
  }

  get cancelMenuAmount(): string {
    return this._cancelMenuAmount;
  }

  get cancelMenuCnt(): string {
    return this._cancelMenuCnt;
  }

  get changeReserve(): string {
    return this._changeReserve;
  }

  get cashStock(): string {
    return this._cashStock;
  }

  get cashExcessOrDeficiency(): string {
    return this._cashExcessOrDeficiency;
  }

  get bankDeposit(): string {
    return this._bankDeposit;
  }

  get editData(): string {
    return this._editData;
  }

  get mediaList(): Array<MonthlyDailyPaymentMedia> {
    return this._mediaList;
  }

}
